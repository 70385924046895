
import PropTypes from 'prop-types'
import React from 'react'
function Footer() {
  return (
    <div>
    <footer id="footer">
    <section>
      <div className="row gtr-uniform">
      <div className="col-4 col-12-small">
      <section>
        <h3>Email</h3>
        <p className="rev Email">ocin</p>
      </section>
      </div>
      <div className="col-4 col-12-small">
      <section>
        <h3>GSM</h3>
        <p className="rev Phone"> 857 </p>
      </section>
      </div>
      <div className="col-4 col-12-small">
      <section>
        <h3>Social</h3>
        <ul className="icons alt">
        <li><a href="https://matthieu-genicot.medium.com/" className="icon brands fa-medium" target="_blank" rel="noopener noreferrer"><span className="label">Medium</span></a></li>
        <li><a href="https://twitter.com/GenicotMatthieu" className="icon brands fa-twitter" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
        <li><a href="https://www.linkedin.com/in/matthieu-genicot-ab127183/" className="icon brands fa-linkedin" target="_blank" rel="noopener noreferrer"><span className="label">Linkedin</span></a></li>
        </ul>
      </section>
      </div>
      </div>
      </section>
    </footer>
    <div id="copyright">
      <ul><li>Réalisé avec <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">Gatsby</a></li><li>Design tiré de <a href="https://html5up.net" target="_blank" rel="noopener noreferrer">HTML5 UP</a></li></ul>
    </div>
    </div>
  )
}
Footer.propTypes = {
    timeout: PropTypes.bool
}
export default Footer