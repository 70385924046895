

import PropTypes from 'prop-types'
import React from 'react'

import { Link } from 'gatsby'

import { globalHistory } from '@reach/router'


class Navbar extends React.Component {


  render() {
    const pathname = globalHistory.location.pathname

  return (
    <nav id="nav">
      <ul className="links">
        <li className={` ${pathname === "/" ? "active" : ""} `}>
          <Link to="/">Accueil</Link>
        </li>
        <li className={` ${pathname === "/qui-suis-je/" ? "active" : ""} `}>
            <Link to="/qui-suis-je/">Qui suis-je&nbsp;?</Link>
        </li>
        <li className={` ${pathname === "/portfolio/" ? "active" : ""} `}>
            <Link to="/portfolio/">Portfolio</Link>
        </li>
        <li className={` ${pathname === "/vu-ailleurs/" ? "active" : ""} `}>
            <Link to="/vu-ailleurs/">Vu ailleurs</Link>
        </li>
        <li className={` ${pathname === "/me-contacter/" ? "active" : ""} `}>
            <Link to="/me-contacter/">Me contacter</Link>
        </li>
      </ul>
      <ul className="icons">
        <li><a href="https://matthieu-genicot.medium.com/" className="icon brands fa-medium" target="_blank" rel="noopener noreferrer"><span className="label">Medium</span></a></li>
        <li><a href="https://twitter.com/GenicotMatthieu" className="icon brands fa-twitter" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
        <li><a href="https://www.linkedin.com/in/matthieu-genicot-ab127183/" className="icon brands fa-linkedin" target="_blank" rel="noopener noreferrer"><span className="label">Linkedin</span></a></li>
      </ul>
    </nav>
    )
  }
}


Navbar.propTypes = {
  timeout: PropTypes.bool,
}

export default Navbar
