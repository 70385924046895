import React from 'react'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'gatsby'
import '../assets/sass/main.scss'
class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNavPanelVisible: false,
      timeout: false,
      navPanelTimeout: false,
    }
    this.toggleNavPanel = this.toggleNavPanel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  toggleNavPanel = () => this.setState({ isNavPanelVisible: !this.state.isNavPanelVisible })
  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }
  handleClickOutside = (event) => {
    if (!this.node.contains(event.target)) {
      if (this.state.isNavPanelVisible) {
        this.toggleNavPanel();
      }
    }
  }
  render() {
    const { isNavPanelVisible } = this.state
    let close = (
      <button
        className="close"
        onClick={() => {
          this.toggleNavPanel()
        }}
      > </button>
    )
    let content;
    if (this.props.location && this.props.location.pathname === '/') {
      content = (
        <div>
          {this.props.children}
        </div>
      )
    } else {
      content = (
        <div id="wrapper" className="page">
          <div>
            {this.props.children}
          </div>
        </div>
      )
    }
    return (
      <body className={` ${isNavPanelVisible ? 'is-navPanel-visible' : ''}`}>
        <div id="wrapper" className="fade-in">
            <Header/>
            <Navbar/>
            {content}
            <Footer timeout={this.state.timeout} />
          </div>
          <a
            id="navPanelToggle"
            onClick={this.toggleNavPanel}
            href="#navPanel"
            className="alt"
          > 
          </a>
          <div id="navPanel" ref={node => this.node = node}>
              <nav>
                <ul className="links">
                  <li className="active"><Link to="/">Accueil</Link></li>
                  <li className="active"><Link to="/qui-suis-je/">Qui suis-je&nbsp;?</Link></li>
                  <li className="active"><Link to="/portfolio/">Portfolio</Link></li>
                  <li className="active"><Link to="/vu-ailleurs/">Vu ailleurs</Link></li>
                  <li className="active"><Link to="/me-contacter/">Me contacter</Link></li>
                </ul>
                <ul className="icons">
                  <li><a href="https://matthieu-genicot.medium.com/" className="icon brands fa-medium" target="_blank" rel="noopener noreferrer"><span className="label">Medium</span></a></li>
                  <li><a href="https://twitter.com/GenicotMatthieu" className="icon brands fa-twitter" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
                  <li><a href="https://www.linkedin.com/in/matthieu-genicot-ab127183/" className="icon brands fa-linkedin" target="_blank" rel="noopener noreferrer"><span className="label">Linkedin</span></a></li>
                </ul>
              </nav>
              {close}
            </div>
        </body>
    )
  }
}
export default Layout