
import PropTypes from 'prop-types'
import React from 'react'
function Header() {
  return (
    <div id="header">
      <h1>Point de vue Data</h1>
      <em>#datajournalisme &nbsp;&nbsp;&nbsp; #datascience &nbsp;&nbsp;&nbsp; #dataviz</em>
    </div>
  )
}
Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}
export default Header